<template>
  <div
    class="grid grid-cols-1 p-10 gap-4 lg:pt-10 lg:text-center relative bg-center bg-cover text-center"
    :style="`background-image: url(${banner_url}); min-height: 200px;`"
  >
    <div class="hero__overlay"></div>

    <!-- School Identity -->
    <div class="relative z-10 text-green-50">
      <p class="text-xl font-bold">{{ school_identity.name }}</p>
      <p>{{ school_identity.address }}</p>
      <p>
        <fa-icon icon="phone"></fa-icon>
        <span class="ml-2">{{ school_identity.contact }}</span>
      </p>
    </div>

    <!-- Social Media -->
    <div class="relative z-10 font-bold text-center text-green-900">
      <a
        target="_blank"
        rel="noopener noreferrer"
        :href="whatsappUrl"
        class="mx-4 text-2xl inline-block"
      >
        <fa-icon :icon="['fab', 'whatsapp']"></fa-icon>
      </a>
      <a
        :href="school_identity.instagram_url"
        class="mx-4 text-2xl inline-block"
      >
        <fa-icon :icon="['fab', 'instagram']"></fa-icon>
      </a>
      <a
        :href="school_identity.facebook_url"
        class="mx-4 text-2xl inline-block"
      >
        <fa-icon :icon="['fab', 'facebook']"></fa-icon>
      </a>
      <a :href="school_identity.youtube_url" class="mx-4 text-2xl inline-block">
        <fa-icon :icon="['fab', 'youtube']"></fa-icon>
      </a>
    </div>

    <p class="z-10 text-green-900">{{ copyright }}</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      banner_url: `${process.env.VUE_APP_API_URL}/storage/main/main-banner.jpg`,
    };
  },
  computed: {
    whatsappUrl() {
      if (this.school_identity.whatsapp === undefined) {
        return;
      }
      return `https://wa.me/${this.school_identity.whatsapp
        .replace(/^0/, "62")
        .replace(/[\W_]/g, "")}`;
    },
    copyright() {
      let currentDate = new Date();
      let endDate =
        currentDate.getFullYear() !== 2021
          ? "-" + currentDate.getFullYear()
          : "";

      return `Copyright 2021${endDate}. ${this.school_identity.name}`;
    },

    ...mapGetters(["school_identity"]),
  },
  name: "FooterSection",
};
</script>

<style lang="scss" scoped>
.hero__overlay {
  background-image: linear-gradient(to bottom, #3da544ee, #3da544ee);

  @apply absolute h-full w-full top-0 left-0;
}
</style>