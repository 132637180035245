import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Toasted from 'vue-toasted'

import '@/assets/css/style.css'
import '@/assets/css/component.scss'

/**
 * Axios Configs ---------------------------------------------------------------
 */
axios.defaults.withCredentials = true
axios.interceptors.request.use(async function (config) {
  config.headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  config.baseURL = process.env.VUE_APP_API_URL
  return config;
})
axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
)
// -----------------------------------------------------------------------------

import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowDown, faArrowLeft, faArrowRight, faBars, faDownload, faEllipsisH, faExclamationTriangle, faFile, faPhone, faQuestionCircle, faSave, faSpinner, faTimes, faUsers } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

library.add(faBars, faTimes, faArrowDown, faArrowLeft, faArrowRight, faWhatsapp, faSave,
  faDownload, faUsers, faInstagram, faFacebook, faYoutube, faArrowRight, faPhone, faSpinner, faExclamationTriangle,
  faEllipsisH, faQuestionCircle, faFile)

Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-icon', FontAwesomeIcon)

Vue.filter('indonesian_date_format', (value) =>
  value ? (new Date(value)).toLocaleDateString('id-ID') : '...'
);
Vue.filter('indonesian_datetime_format', (value) =>
  value ? (new Date(value)).toLocaleString('id-ID') : '...'
);
Vue.filter('time_only', (value) =>
  value ? new Date(value).toLocaleTimeString([], {
    hour: '2-digit', minute: "2-digit", hour12: false
  }) : '...')

Vue.use(Toasted, { duration: 3000 });

Vue.config.productionTip = false

store.dispatch('fetchSchoolIdentity').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
