import Vue from 'vue'
import Vuex from 'vuex'
import student from './student.js'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    school_identity: {}
  },
  getters: {
    school_identity: (state) => state.school_identity
  },
  mutations: {
    SET_SCHOOL_IDENTITY(state, value) {
      state.school_identity = value
    }
  },
  actions: {
    fetchSchoolIdentity({ state, commit }) {
      // prevent refetch when the data is present.
      // since the identity is rare to be changed.
      if (state.school_identity.name !== undefined) {
        return false
      }
      axios.get('api/profile/identity').then(response => {
        commit('SET_SCHOOL_IDENTITY', response.data.school_identity)
      });
    }
  },
  modules: {
    student
  }
})
