<template>
  <div id="app">
    <router-view />
    <footer-section></footer-section>
  </div>
</template>

<script>
import FooterSection from "@/components/FooterSection.vue";

export default {
  components: { FooterSection },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
